import BlogList from "./BlogList";
import { useState } from "react";

const Home = () => {

    const [blogs, setBlogs] = useState([
        {
          title: "My new website",
          body: "lorem ipsum...",
          author: "mario",
          id: 1,
        },
        { title: "Welcome party!", body: "lorem ipsum...", author: "yoshi", id: 2 },
        {
          title: "Web dev top tips",
          body: "lorem ipsum...",
          author: "mario",
          id: 3,
        },
      ]);

    return (
        <div className="home">
            <BlogList blogs={blogs} title="Blogs"/>
            <BlogList blogs={blogs.filter((blog) => blog.author === "mario" )} title="Mario Blog"/>
        </div>
    ); 

}

// Ended in video number 7 start 8 next


export default Home;